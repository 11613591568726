import React, { Component } from "react";
import { ReactComponent as GtSVG } from "../Icons/Gt.svg";
import { ReactComponent as LtSVG } from "../Icons/Lt.svg";
import GalleryModal from '../Modal/GalleryModal';

class ImageGallery extends Component {
    state = {
        images: [],
        currentImage: {},
        currentIndex: 0,
        isGalleryModalOpen: false
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.imageList !== this.props.imageList) {
            this.setImageList(nextProps.imageList);
        }
    }
    componentDidMount() {
        this.setImageList(this.props.imageList);
    }

    setImageList = imageList => {
        if (!imageList || imageList.length === 0) return;

        let newImageList = [...imageList];
        // let images = newImageList.map((img, i) => {
        //     img.selected = i === 0;
        //     return img;
        // });
        this.setState({ images: newImageList, currentImage: newImageList[0], currentIndex: 0 });
    };


    incrementCurrentImage = (e) => {
        let index = this.state.currentIndex;
        let newIndex = index + 1;
        if (newIndex < this.state.images.length) {
            this.setState({ currentIndex: newIndex, currentImage: this.state.images[newIndex] });
        }
        e.stopPropagation();
    }
    decrementCurrentImage = (e) => {
        let index = this.state.currentIndex;
        let newIndex = index - 1;
        if (newIndex >= 0) {
            this.setState({ currentIndex: newIndex, currentImage: this.state.images[newIndex] });
        }
        e.stopPropagation();
    }

    setCurrentIndex = (index) => {
        console.log('index', index);
        this.setState({ currentIndex: index, currentImage: this.state.images[index] });
    }

    closeModal = () => {
        this.setState({ isGalleryModalOpen: false });
    }

    imageClicked = () => {
        this.setState({ isGalleryModalOpen: true })
    }

    render() {
        let { className } = this.props;
        let { currentImage, images } = { ...this.state };
        if (!this.state.images || this.state.images.length === 0) return null;

        return (
            <React.Fragment>
                <div className={[className || null, "ab-image-gallery"].join("")}>
                    <div
                        className='ab-image-gallery__main-image'
                        onClick={this.imageClicked}
                        style={{ backgroundImage: "url(" + currentImage.url + ")" }}
                    >

                        <div
                            className={
                                ["ab-image-gallery__previous"
                                    , this.state.currentIndex === 0 ? "--disabled" : null
                                ].join(' ')}
                            onClick={this.decrementCurrentImage}><LtSVG /> </div>
                        <div className={[
                            "ab-image-gallery__next",
                            (this.state.currentIndex + 1) === this.state.images.length ? "--disabled" : null
                        ].join(' ')} onClick={this.incrementCurrentImage}> <GtSVG />	 </div>

                    </div>
                    <div className='ab-image-gallery__images'>
                        <div className="ab-image-gallery__images-inner">
                            {images.map((img, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={
                                            ['ab-image-gallery__image'
                                                , this.state.currentIndex === i ? "--current-image" : null
                                            ].join(' ')
                                        }
                                        onClick={() => this.setCurrentIndex(i)}
                                        style={{ backgroundImage: "url(" + img.url + ")" }}
                                    >

                                        {/* <img src={img.url} alt='gallery-image' /> */}
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </div>
                <GalleryModal
                    show={this.state.isGalleryModalOpen}
                    closeModal={this.closeModal}
                    currentIndex={this.state.currentIndex}
                    numberOfImages={this.state.images.length}
                    currentImage={this.state.currentImage}
                    incrementCurrentImage={(e) => this.incrementCurrentImage(e)}
                    decrementCurrentImage={(e) => this.decrementCurrentImage(e)}
                />

            </React.Fragment>
        );
    }
}

export default ImageGallery;
