import React, { Component } from "react";
import Marker from "../../assets/img/mapsMarker.png";

class GoogleMaps extends Component {
    googleMapRef = React.createRef();

    UNSAFE_componentWillReceiveProps(nextProp) {
        if (nextProp.mapObj !== this.props.mapObj) {
            this.loadMapScript(nextProp.apiKey, nextProp.mapObj);
        }
    }

    componentDidMount() {
        this.loadMapScript(this.props.apiKey, this.props.mapObj);
    }

    loadMapScript = (key, mapObj) => {
        if (!mapObj || !key) return;

        let googleScript = window.document.getElementById("react-gmap");
        if (!googleScript) {
            const googleScript = document.createElement("script");
            googleScript.src = "//maps.googleapis.com/maps/api/js?key=" + this.props.apiKey + "&libraries=places";
            googleScript.id = "react-gmap";
            window.document.body.appendChild(googleScript);
            googleScript.addEventListener("load", () => {
                this.googleMap = this.createGoogleMap(mapObj.lat, mapObj.lng);
            });
        } else {
            this.googleMap = this.createGoogleMap(mapObj.lat, mapObj.lng);
        }
    };

    createGoogleMap = (lat, lng) => {
        let map = new window.google.maps.Map(this.googleMapRef.current, {
            zoom: this.props.zoom,
            center: { lat: lat, lng: lng },
            disableDefaultUI: true
            // styles: [ ]
        });
        new window.google.maps.Marker({
            position: { lat: lat, lng: lng },
            map: map,
            title: "Hello World!",
            icon: new window.google.maps.MarkerImage(Marker)
        });
    };

    render() {
        return (
            <div
                id='google-map'
                ref={this.googleMapRef}
                style={{
                    width: "100%",
                    height: "300px",
                    marginBottom: "20px"
                    // boxShadow: "0px 2px 6px rgba(0,0,0, .3)"
                }}
            />
        );
    }
}

export default GoogleMaps;
