import React from "react";

const HeadLine = ({title, subTitle, className, ...props}) => {
    return (
        <div className={["ab-headline", className].join(" ")}>
            {title ? <h1>{title}</h1> : null}
            {subTitle ? <p>{subTitle}</p> : null}
        </div>
    );
};

export default HeadLine;
