import React, { Component } from 'react';
import Modal from './Modal';

import ReactPlayer from 'react-player';


// import Btn from '../Btn/Btn';
class VideoModal extends Component {

    render() {
        return (
            <Modal 
                show={this.props.show} 
                backDropClicked={this.props.handleClose }
                video
                >
                    <ReactPlayer url={this.props.url} width='100%' height="70vh" className='react-player' />

            </Modal>
        );
    }
}

export default VideoModal;