import React, { Component } from 'react';
import animateScrollTo from 'animated-scroll-to';


class ScrollToTopArrow extends Component {
    state = {
        scrollposition: null,
        reachedBottom: document.body.scrollHeight - window.pageYOffset === window.innerHeight && window.pageYOffset,
        reachedThird : document.body.scrollHeight / 4 <= window.pageYOffset && window.pageYOffset,
        fetched: false,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        let fetched =  this.state.fetched? true : this.isReachedThird() || this.state.reachedBottom;
        this.setState({ 
            scrollposition: window.pageYOffset, 
            fetched: fetched, 
            reachedBottom: document.body.scrollHeight - window.pageYOffset === window.innerHeight,
            reachedThird : this.isReachedThird(),
        });
    }

    isReachedThird = () => {
        return document.body.scrollHeight / 4 <= window.pageYOffset;
    }

    arrowClicked = () => {
        animateScrollTo(0);
    }

    render() {
        let classes = 
            [ 'scrollTopArrow'
            , this.state.reachedThird || this.state.reachedBottom ? "active" 
                : this.state.fetched? "hide" : ""
            ].join(' ');

        return (
            <div className={classes} onClick={this.arrowClicked}>↑</div>
        );
    }
}

export default ScrollToTopArrow;