import React from 'react';
import cn from 'classnames';

// burgerSlide :: slide on mobile

const Toolbar = ({footer, burgerSlide, wide, theme, darkGrey, primary, accent, error, info, warning, dark, success, indigo, elivated, elivated2, elivated3, lg, sm, className, ...props}) => {
    return (
        <header
            className={cn(
                "ab-toolbar",
                primary && "primary",
                error && "error",
                info && "info",
                warning && "warning",
                dark && "dark",
                success && "success",
                indigo && "indigo",
                accent && "accent",
                darkGrey && "darkGrey",
                theme,

                elivated && "ab-toolbar-elivated",
                elivated2 && "ab-toolbar-elivated2",
                elivated3 && "ab-toolbar-elivated3",
                lg && "ab-toolbar--large",
                sm && "ab-toolbar--small",
                wide && 'ab-toolbar--wide',
                footer && 'ab-toolbar--footer',

                className
            )}
            {...props}
        >
            <div className="ab-toolbar-inner">
                {props.children}
            </div>

        </header>
    );
};

export default Toolbar;