import React, { Component } from 'react';
import Modal from './Modal';


class GalleryModal extends Component {

    render() {
        let {show} = this.props;
        return (
            <Modal 
            isGallery
            show={show || false} 
            backDropClicked={this.props.closeModal}
            className="--gallery-modal"
            incrementCurrentImage={this.props.incrementCurrentImage}
            decrementCurrentImage={this.props.decrementCurrentImage}
            currentIndex={this.props.currentIndex}
            numberOfImages={this.props.numberOfImages}
            >
                <div 
                className='--gallery-modal__inner' 
                style={{ backgroundImage: "url(" + this.props.currentImage.url + ")" }} 
                >
                    
                </div>
            </Modal>
        );
    }
}

export default GalleryModal;