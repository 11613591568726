import React from "react";
import cn from "classnames";
import {ReactComponent as Loader} from "../../assets/img/loader.svg";
import * as validate from '../../extras/validate';


const Btn = ({render, loader, disabled, primary, error, info, warning, dark, success, indigo, flat, depressed, outline, block, lg, sm, xs, className, theme, size, text, to, ...props}) => {

    const renderedBtn =
        <button
            className={cn(
                "ab-btn",
                primary && "primary",
                error && "error",
                info && "info",
                warning && "warning",
                dark && "dark",
                success && "success",
                indigo && "indigo",
                block && "ab-btn--block",
                outline && "ab-btn--outline",
                flat && "ab-btn--flat",
                depressed && "ab-btn--depressed",
                lg && "ab-btn--lg",
                sm && "ab-btn--sm",
                xs && "ab-btn--xs",
                loader && "ab-btn-loader",
                theme,
                size && "ab-btn--" + size,
                className
            )}
            disabled={loader || disabled}
            onClick={props.onClick}
            {...props}
        >
            {text ? text : props.children}
            {loader ? <Loader /> : null}

        </button>

    return (
        <div className={cn("ab-btn__wrapper", block && "--block")}>
            {
                to && validate.isValidHttpUrl(to) ? <a href={to}>{renderedBtn}</a> :
                    render ? render(renderedBtn) :
                        renderedBtn
            }
        </div>
    );
};

export default React.memo(Btn);
