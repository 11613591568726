import React from 'react';
import * as images from '../extras/images';
import * as validate from '../extras/validate';
// import { Link } from 'react-router-dom';


const Img = ({ render, svg, src, to, alt, ...props }) => {

    // let srcUrl = !src ? '' :
    //     validate.isValidHttpUrl(src) ? src :
    //         images.src(src);

    let srcUrl = src || '';

    let img = <img src={srcUrl} alt={alt || "image alt"} />;

    let Svg = svg;

    let imageTag = svg ? <Svg /> : img;

    let tag = to && validate.isValidHttpUrl(to) ? <a href={to} >{imageTag} </a> :
        render ? render(imageTag) :
            imageTag


    return (
        <div className={[
            "ab-image",
            svg && "ab-image--has-svg"
        ].join(" ")}>
            {tag}
        </div>
    );
};

export default Img;