export const validateInput = (element, value) => {
    if (!element.rules)
        return [];

    let errorsAr = [];

    element.rules.map(r => {
        switch (r) {
            case 'email':
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                // let re = /\S+@\S+\.\S+/;
                let valid = re.test(String(value).toLowerCase());
                let failed = valid ? false : true;
                if (failed) {
                    errorsAr.push({type: 'email', errorMessage: 'Email not valid.', priority: 2});
                }
                break;
            case 'required':
                let notValid = false;
                switch (typeof (value)) {
                    case 'string':
                        notValid = !value || value.trim().length <= 0;
                        break;

                    case 'boolean':
                        notValid = !value;
                        break;

                    case 'object':
                        notValid = value === null ? true : Array.isArray(value) && value.length === 0;
                        break;

                    default:
                        notValid = false
                        break;
                }
                if (notValid) {
                    errorsAr.push({type: 'required', errorMessage: 'Required.', priority: 1});
                }
                break;

            case 'date':
                if (!isValidDate)
                    errorsAr.push({type: 'date', errorMessage: 'Please enter a valid Date.', priority: 2});
                break;


            case 'requiredRadio':
                let radioDidntPass = !value || value.length <= 0 ? true : false;
                if (radioDidntPass) {
                    errorsAr.push({type: 'required', errorMessage: 'Please select an option.', priority: 1});
                }
                break;

            case 'card':
                // value here is event
                if (value && value.hasOwnProperty('complete')) {
                    element.complete = value.complete;
                }

                if (value && value.error) {
                    errorsAr.push({type: 'card', errorMessage: value.error.message, priority: 1});
                }
                if (!element.complete) {
                    errorsAr.push({type: 'requiredCard', errorMessage: "Please complete card details!", priority: 2});
                }
                break;

            // case 'radioAccordion':
            //     let form = {...this.state.form};
            //     let selectedOption = element.options.find(o => o.id === value);
            //     let childrenErrors = [];
            //     selectedOption.children.forEach(child => {
            //         let childValue = child.output ? form[child.output][child.slug] : form[child.slug];
            //         this.validate(child, childValue);
            //         if (child.hasError)
            //             childrenErrors.push(child.slug);
            //     });
            //     if (childrenErrors.length > 0)
            //         errorsAr.push({type: 'requiredChild', errorMessage: 'Please fill in child elements.', priority: 1});
            //     break;

            default:
                break;
        }
        return r;
    });

    return errorsAr;
}

const isValidDate = d => d instanceof Date && !isNaN(d);

export const errorMessage = (errors) => {
    const err = errors.reduce((prev, curr) => {
        return prev.priority < curr.priority ? prev : curr;
    }, []);

    return err.errorMessage;
}


// valuesObj -> element[] -> (null | error []) 
export const validateForm = (values, elements) => {
    let errObj = {};
    elements.forEach(el => {
        let errs = validateInput(el, values[el.slug]);
        if (el.type === "dateTime") {
            console.log('errs', errs);
        }
        errObj[el.slug] = errs;
        return el;
    });

    let hasErrors = false;
    Object.keys(errObj).forEach(key => {
        if (errObj[key].length > 0)
            hasErrors = true;
    });

    return hasErrors ? errObj : null;
}