import React from 'react';
import cn from 'classnames';

const Row = ({ gutt1, gutt2, gutt3, gutter1, gutter2, gutter3, className, ...props }) => {
    return (
        <div 
        className={cn(
            "layout",
            gutt1 && "fstart-gutter-1",
            gutt2 && "fstart-gutter-2",
            gutt3 && "fstart-gutter-3",
            gutter1 && "gutter-1",
            gutter2 && "gutter-2",
            gutter3 && "gutter-3",
            className
        )}
        {...props}
        ></div>
    );
};

export default React.memo(Row);