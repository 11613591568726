import React from 'react';
import NavigationItem from './NavigationItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import cn from 'classnames';

const NavigationItems = ({alignLeft, alignRight, alignCenter, column, className, ...props}) => {
    return (
        <ul className={cn(
            'ab-navigation-links',
            className,
            alignLeft && "f-jc-start",
            alignRight && "f-jc-end",
            alignCenter && "f-jc-center",
            column && "ff-column f-aa-start f-fd-column",
        )}>
            {
                props.items.map((item, i) => {
                    return (
                        <NavigationItem
                            key={i}
                            link={item.link}
                            className={item.type === "icon" ? "icon-li" : null}
                            render={props.render}
                            {...item}
                        >
                            { item.type === "icon" ? <FontAwesomeIcon icon={item.icon} size="lg" /> : item.title}
                        </NavigationItem>
                    )
                })
            }
            <React.Fragment>
                {props.children ? props.children : null}
            </React.Fragment>
        </ul>
    );
};

export default NavigationItems;