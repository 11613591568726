import React from 'react';
import cn from "classnames";
import {errorMessage} from '../utils';

const Checkbox = ({theme, primary, error, info, warning, dark, success, indigo, className, ...props}) => {
    return (
        <div className={cn("ab-checkbox", className)}>
            <label >
                <input
                    type="checkbox"
                    className={cn(
                        primary && "primary",
                        error && "error",
                        info && "info",
                        warning && "warning",
                        dark && "dark",
                        success && "success",
                        indigo && "indigo",
                        theme || null
                    )}
                    name={props.name}
                    onChange={props.onChange}
                    checked={props.value}
                />
                <div className="label-holder">
                    {props.label}
                </div>
            </label>
            {
                props.errors ?
                    <div className="ab-checkbox__error-message">
                        {errorMessage(props.errors)}
                    </div>
                    : null
            }
        </div>
    );
};

export default Checkbox;