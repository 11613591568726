import React from "react";
import cn from "classnames";
import {errorMessage, validateInput} from "../utils";

const Switch = ({primary, error, info, warning, dark, success, indigo, theme, block, className, ...props}) => {
    return (
        <div className={cn("ab-switch", className)}>
            <label
                className='ab-switch__label'
                htmlFor={"switch-" + props.name}
            >

                <input
                    type='checkbox'
                    id={"switch-" + props.name}
                    className={cn(
                        "ab-switch__input",
                        primary && "primary",
                        error && "error",
                        info && "info",
                        warning && "warning",
                        dark && "dark",
                        success && "success",
                        indigo && "indigo",
                        theme || null,
                    )}

                    checked={props.value}
                    name={props.name}
                    onChange={e => props.onChange(e)}
                />

                <div className="ab-switch__lever"></div>
                <div className="ab-switch__bar"></div>


                {props.label}
            </label>
            {
                props.errors ?
                    <div className="ab-switch__error-message">
                        {errorMessage(props.errors)}
                    </div>
                    : null
            }
        </div>
    );
};

export default Switch;
