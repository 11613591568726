import React, { Component } from "react";

class Select extends Component {
    _isMounted = false;
    state = {
        focused: false,
        hasValue: false,
        selectedOption: null
    };

    componentWillMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }
    componentWillUnmount() {
        this._isMounted = false;
        document.addEventListener("mousedown", this.handleClick, false);
    }
    handleClick = e => {
        if (this.node && this.node.contains(e.target)) return;

        // only if click is outside the select
        if (this._isMounted) {
            this.setState({ focused: false });
        }
    };
    selectClicked = () => {
        this.setState({ focused: !this.state.focused });
    };

    componentDidMount() {
        this._isMounted = true;
        if (this.props.options) {
            this.props.options.map(option => {
                if (option.selected) this.setState({ selectedOption: option, hasValue: true });

                return option;
            });
        }
    }

    optionClicked = option => {
        this.setState({ selectedOption: option, hasValue: true });
        this.props.changed(option);
    };
    reset = () => {
        this.setState({ selectedOption: null, hasValue: false });
        this.props.reset();
    };

    render() {
        let { label, options, className } = this.props;
        return (
            <div
                ref={node => (this.node = node)}
                className={[
                    "ab-select",
                    className,
                    this.state.focused ? "ab-select--focused" : "",
                    this.state.hasValue ? "ab-select--has-value" : ""
                ].join(" ")}
                onClick={this.selectClicked}
            >
                <div className={"ab-select__wrap"}>
                    <label>{label}</label>

                    <div className='ab-select__select'>
                        <div className='ab-select__select-text'>
                            {!this.state.selectedOption ? "select option" : this.state.selectedOption.text}
                        </div>
                        <div className='ab-select__options'>
                            <div className='ab-select__option' onClick={this.reset}>
                                select option
                            </div>
                            {options.map(option => {
                                return (
                                    <div
                                        key={option.id}
                                        className={[
                                            "ab-select__option",
                                            this.state.selectedOption && this.state.selectedOption.text === option.text
                                                ? "ab-select__option--selected"
                                                : null
                                        ].join(" ")}
                                        onClick={e => this.optionClicked(option)}
                                    >
                                        {option.text}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Select;
