import React, { Component } from 'react';

class OverlayItem extends Component {
    
    state = {
        scrollposition: null,
        reachedBottom: document.body.scrollHeight - window.pageYOffset === window.innerHeight && window.pageYOffset,
        reachedThird : document.body.scrollHeight / 4 <= window.pageYOffset && window.pageYOffset,
        fetched: false,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        let fetched =  this.state.fetched? true : this.isReachedThird() || this.state.reachedBottom;
        this.setState({ 
            scrollposition: window.pageYOffset, 
            fetched: fetched, 
            reachedBottom: document.body.scrollHeight - window.pageYOffset === window.innerHeight,
            reachedThird : this.isReachedThird(),
        });
    }
    isReachedThird = () => {
        return document.body.scrollHeight / 4 <= window.pageYOffset;
    }

    render() {

        let { className, bottom, top, left, right, scrollCondition, rightBottom, 
                // rightTop, topRight, topLeft, leftTop, leftBottom
            } = {...this.props}

        return (
            <div 
            // {...this.props}
            className={
                [ className
                , 'ab-overlayItem'
                , rightBottom && 'ab-rightBottom'
                // 
                // TODO :: rightTop, topRight, topLeft, leftTop, leftBottom
                // 
                , scrollCondition && "scrollCondition"
                , scrollCondition && scrollCondition === "third" ? this.state.reachedThird || this.state.reachedBottom ? "active" 
                    : this.state.fetched? "hide" : "" : ""
                , scrollCondition && scrollCondition === "end" ? this.state.reachedBottom ? "active" 
                    : this.state.fetched? "hide" : "" : ""
            ].join(' ')}
            
            style={{
                top: top? top+'px' : null,
                bottom: bottom? bottom+'px' : null,
                left: left? left+'px' : null,
                right: right? right+'px' : null,
            }}

            >
                {this.props.children}
            </div>
        );
    }
}

export default OverlayItem;
