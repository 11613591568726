import React, {useEffect, useState} from 'react';
import cn from 'classnames';

import Row from './Grid/Row/Row';
import Col from './Grid/Col/Col';
import HeadLine from './HeadLine';
import HTMLContent from './HTMLContent/HTMLContent';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from "@fortawesome/free-solid-svg-icons";

const Carousel = ({list, intervals, heading, subHeading, className, id, isHtmlContent, primary, error, success, warning, info, indigo, accent, theme, dark, darkbk, ...props}) => {
    const [newList, setNewList] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        let items = !list ? [] : list.map((t, i) => {
            setCurrentStep(0);
            return {...t, step: i}
        })

        setNewList(items);

        let v = setInterval(() => {
            setCurrentStep(s => s + 1 <= items.length - 1 ? s + 1 : 0);
        }, intervals || 3000);

        return () => clearInterval(v);
    }, [list, intervals]);

    const changeStep = (step) => {
        if (step < 0 || step > newList.length - 1) return;
        setCurrentStep(step);
    }

    return (
        <Row
            id={id || undefined}
            className={cn(
                "ab-carousel",
                darkbk && 'ab-carousel--darkbk',
                primary && "primary",
                error && "error",
                info && "info",
                warning && "warning",
                dark && "dark",
                success && "success",
                indigo && "indigo",
                accent && "accent",
                theme,
                className,
            )}
        >
            <Col md8 mdOffset2 >
                <div className="ab-carousel__inner">

                    <HeadLine title={heading || ''} />
                    {!subHeading ? null : <HTMLContent className="ab-carousel__subheading" content={subHeading} />}

                    <div
                        className="ab-carousel__wrap"
                        style={{
                            width: `${newList.length * 100}%`,
                            left: currentStep === 0 ? '0px' : `-${100 * currentStep}%`
                        }}
                    >
                        {
                            newList.map((t) => {
                                return (
                                    <div
                                        key={t.step}
                                        className={cn(
                                            "ab-carousel__item",
                                            t.step === currentStep && "carousel--current"
                                        )}
                                    >
                                        <div className="ab-carousel__item-content"> {!t.content ? null : isHtmlContent ? <HTMLContent content={t.content} /> : <p>{t.content}</p>} </div>
                                        <div className="ab-carousel__author">
                                            <div className="ab-carousel__author-profile">
                                                <FontAwesomeIcon icon={faUser} color="white" size="sm" />
                                            </div>
                                            <p>{t.author || ""}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>

                    <div className="ab-carousel__bullets">
                        {
                            newList.map(ts => {
                                return (
                                    <div
                                        key={ts.step}
                                        className={cn("ab-carousel__bullet", currentStep === ts.step && "ab-carousel__bullet--active-bullet")}
                                        onClick={() => setCurrentStep(ts.step)}
                                    >
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="ab-carousel__controls large-device-only">
                        <div
                            className={cn("ab-carousel__controls-prev", currentStep === 0 && "--disabled")}
                            onClick={() => changeStep(currentStep - 1)}
                        >
                            &#8249;
                    </div>
                        <div
                            className={cn("ab-carousel__controls-next", currentStep === newList.length - 1 && "--disabled")}
                            onClick={() => changeStep(currentStep + 1)}
                        >
                            &#8250;
                    </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default Carousel;