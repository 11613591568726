import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faTwitter, faInstagram, faYoutube, faGooglePlus, faPinterest, faLinkedinIn, faVimeo, faAmazon, faSpotify} from '@fortawesome/free-brands-svg-icons'
// import Classes from './Social.module.css';

const Social = ({followText, vertical, color, alignRight, alignLeft, alignCenter, className, ...props}) => {

    let {vimeoLink, facebookLink, twitterLink, instagramLink, youtubeLink, googlePlusLink, pinterestLink, linkedinLink, spotifyLink, amazonLink} = props.data ? props.data : {};
    let mysize = props.size ? props.size : "lg";


    let ulClasses = [
        alignLeft && "f-jc-start",
        alignRight && "f-jc-end",
        alignCenter && "f-jc-center",
        vertical && "ff-column"
    ].join(' ');

    return (
        <div className={[
            'ab-social',
            className,
            vertical && 'vertical'
        ].join(' ')}>
            <ul className={ulClasses}>
                {facebookLink && <li><a href={facebookLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faFacebookF} size={mysize} color={color} /></a></li>}
                {twitterLink && <li><a href={twitterLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faTwitter} size={mysize} color={color} /></a></li>}
                {instagramLink && <li><a href={instagramLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faInstagram} size={mysize} color={color} /></a></li>}
                {youtubeLink && <li><a href={youtubeLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faYoutube} size={mysize} color={color} /></a></li>}
                {googlePlusLink && <li><a href={googlePlusLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faGooglePlus} size={mysize} color={color} /></a></li>}
                {pinterestLink && <li><a href={pinterestLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faPinterest} size={mysize} color={color} /></a></li>}
                {linkedinLink && <li><a href={linkedinLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faLinkedinIn} size={mysize} color={color} /></a></li>}
                {vimeoLink && <li><a href={vimeoLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faVimeo} size={mysize} color={color} /></a></li>}
                {amazonLink && <li><a href={amazonLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faAmazon} size={mysize} color={color} /></a></li>}
                {spotifyLink && <li><a href={spotifyLink} target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faSpotify} size={mysize} color={color} /></a></li>}
            </ul>

        </div>
    );
};

export default Social;