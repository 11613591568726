import React, {useState, useEffect, useRef} from "react";

const formSelect = ({value, slug, options, label, ...props}) => {
    let [focused, setFocused] = useState(false);
    let selectRef = useRef();

    useEffect(() => {
        if (value === "") {
            props.onChange({target: {name: slug, value: options[0].key}});
        }
    }, []);

    const inputClicked = e => {
        setFocused(true);
        selectRef.current.focus();
    };

    return (
        <div
            className={[
                'ab-form-select',
                value ? 'ab-form-select--has-value' : null,
                focused ? 'ab-form-select--active' : null,
            ].join(' ')}
        >
            <div className='ab-form-select__inner' onClick={inputClicked}>
                <div className='ab-form-select__label-wrap'>
                    <label>{label}</label>

                    <select
                        name={slug}
                        ref={selectRef}
                        onBlur={() => setFocused(false)}
                        onChange={(e) => props.onChange(e, [])}
                        value={value}
                    >
                        {options.map(option => {
                            return (<option key={option.key} value={option.key} >{option.text}</option>);
                        })}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default React.memo(formSelect);
