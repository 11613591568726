import React, {Component} from "react";
import HTMLContent from "../HTMLContent/HTMLContent";

class Card extends Component {
    state = {
        hovered: false
    };

    render() {
        let {img, imgWithOverlay, elevated, link, previewText, height, className} = {...this.props};
        let styles = {
            ...(img ? {backgroundImage: `url(${img})`} : null),
            ...(height ? {minHeight: height + "px"} : null)
        };

        let cardContent = (
            <div className='ab-card__img-wrap' style={styles}>
                {
                    !imgWithOverlay ? null :
                        <div className='ab-card__overlay-content'>
                            <div className='ab-card__title-wrap'>
                                <h3>{this.props.title}</h3>
                            </div>
                            <HTMLContent className='previewContent' content={previewText} />
                        </div>
                }
            </div>
        );

        let contentIfImageOverlay = (
            <div className="ab-card__body">
                <h3 >
                    {this.props.renderLink ? this.props.renderLink(link, this.props.title) :
                        <a href={link}>{this.props.title}</a>
                    }

                </h3>
            </div>
        );
        return (
            <div className={["ab-card", className, elevated && "ab-card--elevated"].join(" ")}>
                {this.props.renderLink ? this.props.renderLink(link, cardContent) : <a href={link}> {cardContent} </a>}
                { imgWithOverlay ? null : contentIfImageOverlay}
            </div>
        );
    }
}

export default Card;
