import React, {useState, useEffect, useRef} from 'react';
import cn from 'classnames';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import {errorMessage} from '../utils';

const DateTime = ({theme, label, value, name, errors, minDate, maxDate, enableTime, ...props}) => {
    const [errs, setErrors] = useState([]);
    const [focused, setFocused] = useState(false);
    const fp = useRef(null);

    useEffect(() => setErrors(errors), [errors]);

    const fpOptions = {
        locale: {firstDayOfWeek: 1},
        dateFormat: 'd-m-Y h:i K',
        onReady: (_, __, fp) => fp.calendarContainer.classList.add(theme || null),
        onChange: (e) => props.onChange({target: {name: name, value: e[0]}}),
        onClose: () => setFocused(false),
        minuteIncrement: 15,
        minDate: minDate || null,
        maxDate: maxDate || null,
        enableTime: enableTime || null,
        disableMobile: "true"
    }


    const inputClicked = () => {
        setFocused(true);
        setTimeout(() => fp.current.flatpickr.open(), 0)
    }

    return (
        <div
            className={cn(
                'ab-input',
                'ab-dateTime',
                value ? 'ab-input--has-value' : null,
                focused ? 'ab-input--active' : null,
                errs.length > 0 ? 'ab-input--has-error' : null
            )}
        >
            <div className='ab-input__inner' onClick={inputClicked} >
                <div className='ab-input__label-wrap'>
                    <label >{label}</label>
                    <Flatpickr ref={fp} value={value} options={fpOptions} />
                </div>
            </div>

            {
                errs.length > 0 ?
                    <div className='ab-input__error-wrap uppercase-fl'>
                        <div className='ab-input__error-message'>
                            {errorMessage(errs)}
                        </div>
                    </div>
                    : null
            }
        </div>
    );
};

export default DateTime;
