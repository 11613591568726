import React from "react";
// import { ReactComponent as LogooSVG } from "../../../assets/img/logo-new.svg";
import {ReactComponent as LoaderSVG} from "../../assets/img/loader.svg";
import Loader from "./Loader";
import cn from 'classnames';

const SiteLoader = ({invert, progress, show, noAnimation, className, ...props}) => {
    // window.addEventListener("load", () => {
    //     const logo = document.querySelectorAll("#svglogo path");
    //     console.log("logo", logo);
    //     for (let i = 0; i < logo.length; i++) {
    //         console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
    //     }
    // });

    return (
        <div
            className={cn(
                "ab-siteLoader",
                show && !noAnimation ? "show" : null,
                noAnimation && "noAnimation",
                className,
                invert && "invert"
            )}
        >


            {props.children || <div className="ab-siteLoader__text">{process.env.REACT_APP_TITLE}</div>}
            {progress ? <Loader show invert={!invert} className='mt-2' /> : <LoaderSVG />}


            <div className='inner'></div>
        </div>
    );
};

export default SiteLoader;
