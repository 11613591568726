import React, {useEffect, useState} from 'react';
import Container from "../Grid/Container/Container";
import Row from "../Grid/Row/Row";
import Col from "../Grid/Col/Col";
import FormElement from './FormElement';
import MultiStepForm from './MultiStepForm';
import {validateInput, validateForm} from './utils';
import Btn from '../Btn/Btn';

const Form = ({theme, btnSize, blockBtn, steps, elements, values, ...props}) => {
    const [errs, setErrs] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let obj = {};
        elements.map(el => obj[el.slug] = []);
        setErrs(obj);
    }, []);

    let elementValue = (e, element) => {
        return ['checkbox', 'switch'].includes(element.type) ? e.target.checked : e.target.value;
    }

    const onChange = (e, element) => {
        let val = elementValue(e, element);
        let newValues = {...values, [e.target.name]: val};
        let errors = validateInput(element, val);
        setErrs({...errs, [element.slug]: errors});
        props.onChange(newValues);
    }

    const onsubmit = (e) => {
        setLoading(true);
        let errors = validateForm(values, elements);
        if (errors) {
            setErrs(errors);
            setLoading(false);
            return;
        }
        props.onSubmit();
        setLoading(false);
    }

    return (
        <Container>
            <Row >
                <Col md12>
                    {steps ? <MultiStepForm steps={steps} /> :
                        <Row gutter1>
                            {
                                elements.map(element => {
                                    return (
                                        <Col className="mb-3" grid={element.grid} key={element.slug} >
                                            <FormElement
                                                theme={theme}
                                                value={values[element.slug]}
                                                element={element}
                                                errors={errs[element.slug] || []}
                                                onChange={(e, element) => onChange(e, element)}
                                            />
                                        </Col>
                                    )
                                })
                            }
                            <Col md12><Btn theme={theme} loader={loading} block={blockBtn} size={btnSize} text="submit" onClick={onsubmit} /> </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </Container>

    );
};

export default Form;