import React, { Component } from "react";
import { ReactComponent as GtSVG } from "../Icons/Gt.svg";
import { ReactComponent as LtSVG } from "../Icons/Lt.svg";

class Modal extends Component {
    state = {
        fetched: false
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show) {
            if (nextProps.show) {
                this.setState({ fetched: true });
            }
        }
    }

    bkClicked = e => {
        e.stopPropagation();
        if (!this.props.dontClose) {
            this.props.backDropClicked();
        }
    };

    render() {
        let { title, show, className, hasMessage, video, isGallery } = { ...this.props };

        return (
            <div
                className={[
                    "ab-modal",
                    className,
                    show ? "ab-modal--active" : this.state.fetched ? "ab-modal--hide" : "",
                    video ? "ab-modal--video-modal" : null,
                    hasMessage ? "ab-modal--has-message" : null
                ].join(" ")}
            >
                <div className='ab-modal__backdrop' onClick={this.bkClicked}>
                    <div className='ab-modal__close'></div>
                    {
                        !isGallery ? null :
                            <React.Fragment>
                                <div
                                    className={
                                        ["--gallery-modal__previous"
                                            , this.props.currentIndex === 0 ? "--disabled" : null
                                        ].join(' ')}
                                    onClick={this.props.decrementCurrentImage}><LtSVG /> </div>
                                <div className={[
                                    "--gallery-modal__next",
                                    (this.props.currentIndex + 1) === this.props.numberOfImages ? "--disabled" : null
                                ].join(' ')} onClick={this.props.incrementCurrentImage}> <GtSVG />	 </div>
                            </React.Fragment>
                    }
                </div>

                <div className='ab-modal__content'>
                    {title ? (
                        <div className='ab-modal__title'>
                            {" "}
                            <h2>{title}</h2>
                        </div>
                    ) : null}

                    <div className='ab-modal__body'>
                        {/* {hasMessage? <p className="ab-modal__message"> {message}</p> : null} */}
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
