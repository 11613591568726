import React, {useEffect, useState} from 'react';
import cn from 'classnames';

const Drawer = ({isOpen, onClose, right, ...props}) => {
    let body = window.document.getElementsByTagName("BODY")[0];
    const [wasOpen, setWasOpen] = useState(false);
    useEffect(() => {
        return () => {
            body.style.overflow = "auto";
            body.style.paddingRight = "0px";
            onClose();
        }
    }, []);

    useEffect(() => {
        if (isOpen) setWasOpen(true);
        setBodyScroll();
    }, [isOpen]);

    const setBodyScroll = () => {
        body.style.overflow = isOpen ? "hidden" : "auto";
        // offset scrollbar width if there is a scrollbar
        if (body.clientHeight > window.innerHeight) {
            body.style.paddingRight = isOpen ? "6px" : "0px";
        }
    };

    return (
        <div
            className={cn(
                "ab-drawer",
                isOpen ? "ab-drawer--open" : "ab-drawer--close",
                right ? "ab-drawer--right" : "ab-drawer--left",
                wasOpen && "ab-drawer--wasOpen"
            )}
        >
            <div className='ab-drawer__backdrop' onClick={onClose}></div>
            <div className='ab-drawer__content'>
                <div className='ab-drawer__close' onClick={onClose}></div>
                <div className="ab-drawer__content-inner">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default Drawer;