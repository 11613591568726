import React from 'react';
import TextInput from './elements/TextInput';
import FormSelect from './elements/FormSelect';
import HeadLine from '../HeadLine';
import Switch from './elements/Switch';
import Checkbox from './elements/Checkbox';
import Radio from './elements/Radio';
import DateTime from './elements/DateTime';

const FormElement = ({theme, element, value, errors, ...props}) => {
    switch (element.type) {
        case 'text':
            return (
                <TextInput
                    name={element.slug}
                    value={value}
                    type={element.type}
                    label={element.label}
                    errors={errors}
                    onChange={(e) => props.onChange(e, element)}
                />
            )

        case 'password':
            return (
                <TextInput
                    name={element.slug}
                    value={value}
                    type={element.type}
                    label={element.label}
                    errors={errors}
                    onChange={(e) => props.onChange(e, element)}
                />
            )

        case 'textarea':
            return (
                <TextInput
                    name={element.slug}
                    value={value}
                    type={element.type}
                    label={element.label}
                    errors={errors}
                    onChange={(e) => props.onChange(e, element)}
                />
            )

        case 'dateTime':
            let {minDate, maxDate, enableTime} = element.options || null;
            return (
                <DateTime
                    theme={theme}
                    name={element.slug}
                    value={value}
                    label={element.label}
                    errors={errors}
                    onChange={(e) => props.onChange(e, element)}
                    minDate={minDate || null}
                    maxDate={maxDate || null}
                    enableTime={enableTime || false}
                />
            )

        case 'select':
            return (
                <FormSelect
                    slug={element.slug}
                    options={element.options}
                    label={element.label}
                    name={element.slug}
                    value={value}
                    onChange={(e) => props.onChange(e, element)}
                />
            )

        case 'checkbox':
            return (
                <Checkbox
                    theme={theme}
                    className="mt-2"
                    value={value}
                    label={element.label}
                    name={element.slug}
                    errors={errors}
                    onChange={(e) => props.onChange(e, element)}
                />
            )
        case 'switch':
            return (
                <Switch
                    theme={theme}
                    className={props.className}
                    value={value}
                    label={element.label}
                    name={element.slug}
                    errors={errors}
                    onChange={(e) => props.onChange(e, element)}
                />
            )

        case 'radio':
            return (
                <Radio
                    block={element.display && element.display === "block"}
                    theme={theme}
                    className={props.className}
                    value={value}
                    label={element.label}
                    name={element.slug}
                    errors={errors}
                    options={element.options}
                    onChange={(e) => props.onChange(e, element)}
                />
            )

        case 'heading':
            return (<HeadLine title={element.title} subTitle={element.subTitle} />)

        case 'title':
            return (
                <div>
                    <p>{element.title}</p>
                    <p>{element.subTitle}</p>
                </div>
            )


        default:
            return;
    }
};

export default FormElement;
