import React from 'react';

const MultiStepForm = () => {
    return (
        <div>
            MultiStepForm
        </div>
    );
};

export default MultiStepForm;