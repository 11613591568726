import React from 'react';
import Img from './Img';
import * as validate from '../extras/validate';

const Logo = ({ text, ...props }) => {

    let renderedText = text || process.env.REACT_APP_TITLE;

    return (
        <div className="ab-logo">
            {
                props.svg || props.src ? <Img svg={props.svg} {...props} /> :
                    props.to && validate.isValidHttpUrl(props.to) ? <a href={props.to} >{renderedText} </a> :
                        props.render ? props.render(renderedText) : renderedText

            }
        </div>
    );
};

export default Logo;