import React from "react";
import animateScrollTo from 'animated-scroll-to';
import * as validate from '../../extras/validate';

let scrollToFragmant = f => {
    animateScrollTo(document.querySelector(f));
}

const NavigationItem = props => {
    return (
        <li style={{position: props.type === "icon" ? "static" : null}}>
            {
                props.type === "fragmant" ?
                    <a href={props.link} onClick={() => scrollToFragmant(props.link)} className={props.palaceClass} > {props.children} </a>
                    : props.link && validate.isValidHttpUrl(props.link) ? <a href={props.link} > {props.children} </a> :
                        props.render(props.link, props.children)
            }
        </li>
    );
};

export default NavigationItem;
