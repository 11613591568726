import React from 'react';
import cn from 'classnames';

const Container = ({ wide, stretch, fluid, className, ...props }) => {
    return (
        <div 
        className={cn(
            "container",
            fluid && "container--fluid",
            wide && "container--wide",
            stretch && "container--stretch",
            className
        )}
        {...props}
        ></div>
    );
};

export default React.memo(Container);