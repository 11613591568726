import React, {useEffect, useState} from 'react';
import cn from "classnames";

const Snackbar = ({show, title, message, timeOut, onComplete, ...props}) => {
    useEffect(() => {
        const timeout = setTimeout(() => onComplete(), timeOut || 3000);
        return () => clearTimeout(timeout);
    }, [show]);

    return (
        <div className={cn('ab-snackbar', show && 'ab-snackbar--active')}>
            <h1>{title}</h1>
            <p>{message}</p>
        </div>
    );
};

export default Snackbar;