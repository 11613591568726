import React, {Component} from "react";
import Pagination from "./Pagination";
import Container from "../Grid/Container/Container";
import Row from "../Grid/Row/Row";
import Col from "../Grid/Col/Col";
import Loader from "../SiteLoader/Loader";
import Card from "../Cards/Card";

class PaginatedContent extends Component {
    state = {
        offset: 0
    };

    onPageChanged = data => {
        const {currentPage, pageLimit} = data;
        const offset = (currentPage - 1) * pageLimit;
        this.setState({offset});
    };

    render() {
        let {data, pageLimit, isLoading, linkPrefix, grid} = {...this.props};
        const currentPosts = data.slice(this.state.offset, this.state.offset + pageLimit);
        // TODO :: currently it displays content as cards digest, we need to add more options
        return (
            <div>
                <Container>
                    <Loader show={isLoading} />
                    <Row gutt3 className='py-4'>
                        {currentPosts.map(p => {
                            return (
                                <Col md3={!grid} grid={grid || undefined} sm12 key={p.id}>
                                    <Card
                                        renderLink={this.props.renderLink || undefined}
                                        elevated
                                        imgWithOverlay
                                        className='mb-4'
                                        img={p.img_url}
                                        title={p.title}
                                        previewText={p.previewParagraph}
                                        link={linkPrefix + p.slug}
                                    />
                                </Col>
                            );
                        })}
                        <Pagination
                            totalRecords={data.length}
                            pageLimit={pageLimit}
                            pageNeighbours={1}
                            onPageChanged={this.onPageChanged}
                        />
                    </Row>
                </Container>
            </div>
        );
    }
}

export default PaginatedContent;
