

export const isValidHttpUrl = string => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    console.log(string);
    return url.protocol === "http:" || url.protocol === "https:";
}