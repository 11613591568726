import React from "react";
import cn from "classnames";
import {errorMessage} from "../utils";

const Radio = ({primary, error, info, warning, dark, success, indigo, theme, block, className, ...props}) => {
    return (
        <div className={cn(
            "ab-radio",
            className,
            primary && "primary",
            error && "error",
            info && "info",
            warning && "warning",
            dark && "dark",
            success && "success",
            indigo && "indigo",
            theme || null,
            block && 'block',
        )}
        >
            <div className="df f-aa-center">
                <p>{props.name}</p>
                {
                    props.errors ?
                        <div className="ab-radio__error-message">
                            {errorMessage(props.errors)}
                        </div>
                        : null
                }
            </div>

            <div className="ab-radio__options">
                {props.options.map(option => {
                    return (
                        <div className="ab-radio__option" key={option.key} >
                            <label className="ab-radio__label" htmlFor={"radio-" + option.key}>
                                <input
                                    className="ab-radio__input"
                                    name={props.name}
                                    onChange={props.onChange}
                                    type="radio"
                                    id={"radio-" + option.key} value={option.key} />
                                <div className="ab-radio__label-text">{option.label}</div>
                            </label>
                        </div>
                    )
                })}
            </div>

        </div >
    );
};

export default Radio;
