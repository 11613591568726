import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {errorMessage} from '../utils';

const textInput = ({type, label, value, name, rules, errors, ...props}) => {
    const [errs, setErrors] = useState([]);
    const [focused, setFocused] = useState(false);
    const inputRef = useRef();

    useEffect(() => {setErrors(errors);}, [errors]);
    const inputClicked = () => {setFocused(true); inputRef.current.focus();}
    let Tag = type === "textarea" ? "textarea" : "input";

    return (
        <div
            className={cn(
                'ab-input',
                value ? 'ab-input--has-value' : null,
                focused ? 'ab-input--active' : null,
                errs.length > 0 ? 'ab-input--has-error' : null
            )}
        >
            <div className='ab-input__inner' onClick={inputClicked} >
                <div className='ab-input__label-wrap'>
                    <label >{label}</label>
                    <Tag
                        name={name}
                        type={type}
                        value={value}
                        onBlur={() => setFocused(false)}
                        onKeyUp={() => setFocused(true)}
                        onFocus={inputClicked}
                        autoComplete='off'
                        ref={inputRef}
                        onChange={(e) => props.onChange(e)}
                    />
                </div>
            </div>
            {
                errs.length > 0 ?
                    <div className='ab-input__error-wrap uppercase-fl'>
                        <div className='ab-input__error-message'>
                            {errorMessage(errs)}
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default textInput;