import React from 'react';
import cn from 'classnames';

const Loader = ({primary, error, info, warning, dark, success, indigo, invert, show, className, ...props}) => {
    return (
        <div className={cn(
            className,
            "ab-loader",
            primary && "primary",
            error && "error",
            info && "info",
            warning && "warning",
            dark && "dark",
            success && "success",
            indigo && "indigo",
            show && "ab-loader--show",
            invert && 'invert'

        )}
            {...props}
        >

        </div >
    );
};

export default Loader;